import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { OrderDataStoreService } from '../orders/services/order-data-store.service';
import { ToastService } from 'src/app/services/toast.service';
import { OrderFormDataService } from '../orders/services/order-form-data.service';
import { HandleHttpErrorService } from './handle-request-errors-service';
import { LoadingController } from '@ionic/angular';
import { FleetUserService } from './fleet-user.service';

@Injectable({
  providedIn: 'root',
})
export class FleetDataLoaderService {
  constructor(
    private fleetUserService: FleetUserService,
    private errorHandler: HandleHttpErrorService,
    private loadingCtrl: LoadingController,
    private orderFormDataService: OrderFormDataService,
    private orderDataStore: OrderDataStoreService,
    private toastService: ToastService,
  ) {}

  async loadFleetUser() {
    const loading = await this.loadingCtrl.create();
    loading.present();

    try {
      const user = await lastValueFrom(this.fleetUserService.getCurrentUser());

      loading.dismiss();

      return user;
    } catch (err: any) {
      loading.dismiss();

      this.errorHandler.handleError(err);
    }

    return null;
  }

  async loadOfferTypes() {
    try {
      const offerTypes = await lastValueFrom(
        this.orderFormDataService.getOfferTypes(),
      );

      this.orderDataStore.offerTypes.set(offerTypes);
    } catch (err: any) {
      this.toastService.showErrorToast(
        new Error('movacarfleet_error_loading_offer_types'),
      );
    }
  }

  async loadBrands() {
    try {
      const brands = await lastValueFrom(this.orderFormDataService.getBrands());

      this.orderDataStore.brands.set(brands);
    } catch (err: any) {
      this.toastService.showErrorToast(
        new Error('movacarfleet_error_loading_brands'),
      );
    }
  }
}
