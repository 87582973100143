import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { SalesforceClientService } from './salesforce-client.service';
import { FleetUser } from '../types';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FleetUserService {
  private _current: WritableSignal<FleetUser | null> = signal(null);

  constructor(private client: SalesforceClientService) {}

  get current() {
    return this._current as Signal<FleetUser | null>;
  }

  getCurrentUser() {
    return this.client
      .request<FleetUser>('GET', '/v2/users/current')
      .pipe(tap((user) => this._current.set(user)));
  }
}
