import { computed, Injectable, signal } from '@angular/core';
import { SelectOption } from 'src/app/fleet/orders/types';

@Injectable({
  providedIn: 'root',
})
export class OrderDataStoreService {
  offerTypes = signal<SelectOption[] | null>(null);
  brands = signal<string[] | null>(null);

  brand = computed(() => this.brands()?.[0]);

  getOfferTypeLabel(offerType: string) {
    return this.offerTypes()?.find((type) => type.value === offerType)
      ?.translationKey;
  }
}
