import { Injectable } from '@angular/core';
import { SalesforceClientService } from '../../services/salesforce-client.service';

import {
  StationsResponse,
  StationContactPersonResponse,
  MakesAndModelsResponse,
  CategoriesResponse,
  CodeOption,
  SelectOption,
  ProductCost,
  CostParams,
} from '../types';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class OrderFormDataService {
  constructor(private salesforce: SalesforceClientService) {}

  getRefuelOptions(offerType: string) {
    return this.salesforce.request<SelectOption[]>('GET', '/v1/fuels', {
      params: { offerType },
    });
  }

  getMakesAndModels() {
    return this.salesforce
      .request<MakesAndModelsResponse>('GET', '/v1/makesAndModels')
      .pipe(
        map((response: MakesAndModelsResponse) =>
          Object.entries(response).map(([name, models]) => ({
            name,
            models,
          })),
        ),
      );
  }

  getLocations(brand?: string) {
    let params: Record<string, any> = { list: 1, all: 1 };

    if (brand) {
      params = { brands: brand };
    }

    return this.salesforce.request<StationsResponse>('GET', '/v1/locations', {
      params,
    });
  }

  getStationContactPersons(stationCode: string) {
    return this.salesforce.request<StationContactPersonResponse>(
      'GET',
      `/v1/location/${stationCode}/users`,
    );
  }

  getCosts(params: CostParams) {
    return this.salesforce.request<ProductCost[]>('GET', '/v1/costs', {
      params,
    });
  }

  getCategories() {
    return this.salesforce.request<CategoriesResponse>('GET', '/v1/categories');
  }

  getBrands() {
    return this.salesforce.request<string[]>('GET', '/v1/brands');
  }

  getGears() {
    return this.salesforce.request<CodeOption[]>('GET', '/v1/gears');
  }

  getFuelTypes() {
    return this.salesforce.request<CodeOption[]>('GET', '/v1/fuelTypes');
  }

  getOfferTypes() {
    return this.salesforce.request<SelectOption[]>('GET', '/v1/offerTypes');
  }

  getExtraProducts(offerType: string) {
    return this.salesforce.request<ProductCost[]>('GET', `/v1/extraProducts`, {
      params: { offerType },
    });
  }
}
