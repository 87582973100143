import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, switchMap, from } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

interface WithToken<T> {
  (token: string): Observable<T>;
}

@Injectable({
  providedIn: 'root',
})
export class SalesforceClientService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {}

  private getToken() {
    return from(this.storageService.get('APEX_TOKEN'));
  }

  private withToken<T>(callback: WithToken<T>) {
    return this.getToken().pipe(switchMap(callback));
  }

  request<T>(method: string, url: string, options?: any) {
    return this.withToken((token) => {
      const actualUrl = `${environment.salesforceUrl}${url}`;

      return this.http.request<T>(
        method,
        actualUrl,
        getOptions(token, options),
      ) as Observable<T>;
    });
  }
}

function getOptions(token: string, options?: any) {
  const { headers, ...rest } = options || {};

  return {
    headers: { token: `${token}`, ...headers },
    ...rest,
  };
}
