import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { commonModules } from '../../common';
import { FleetDataLoaderService } from '../../services/fleet-data-loader.service';

interface MenuItem {
  title: string;
  icon: string;
  url: string;
  access: string;
}

export const fleetMenu: MenuItem[] = [
  {
    title: 'movacarfleet_menu_orders',
    icon: 'car',
    url: '/fleet/orders',
    access: '*',
  },
  {
    title: 'movacarfleet_menu_locations',
    icon: 'map',
    url: '/fleet/locations',
    access: 'locationsAccess',
  },
  {
    title: 'movacarfleet_menu_contacts',
    icon: 'people',
    url: '/fleet/contacts',
    access: 'usersAccess',
  },
  {
    title: 'movacarfleet_menu_reports',
    icon: 'bar-chart',
    url: '/fleet/reports',
    access: 'reportAccess',
  },
];

@Component({
  selector: 'app-fleet-menu',
  templateUrl: './fleet-menu.component.html',
  styleUrls: ['./fleet-menu.component.scss'],
  standalone: true,
  imports: commonModules,
})
export class FleetMenuComponent implements OnInit {
  menu: WritableSignal<MenuItem[]> = signal(
    fleetMenu.filter((menu) => menu.access === '*'),
  );

  constructor(private fleetDataLoader: FleetDataLoaderService) {}

  ngOnInit() {
    this.loadFleetUser();
    this.fleetDataLoader.loadOfferTypes();
    this.fleetDataLoader.loadBrands();
  }

  async loadFleetUser() {
    const user = await this.fleetDataLoader.loadFleetUser();

    if (!user) {
      return;
    }

    this.menu.set(
      fleetMenu.filter((menu) => user[menu.access] || menu.access === '*'),
    );
  }
}
