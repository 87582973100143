import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastCtrl: ToastController,
    private translateService: TranslateService,
  ) {
    this.showErrorToast = this.showErrorToast.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  showToast(
    type: 'success' | 'danger' | 'warning',
    message: string,
    onShowToast: Function = () => {},
    duration?: number,
  ) {
    this.toastCtrl
      .create({
        message: this.translateService.instant(message),
        duration: duration || 3000,
        position: 'top',
        color: type,
      })
      .then((toast) => {
        onShowToast();
        toast.present();
      });
  }

  showErrorToast(error: Error, onDone?: () => void) {
    this.showToast('danger', this.translateService.instant(error.message));
    if (onDone) {
      onDone();
    }
  }
}
