import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/angular-ivy';
import { BreadcrumbHint } from '@sentry/angular-ivy';
import { Breadcrumb } from '@sentry/types/types/breadcrumb';
import { StorageService } from '../services/storage.service';

interface LoginResponse {
  refreshToken: string;
  apexToken: string;
}

export enum UserRole {
  Manager = 'Manager',
  Driver = 'Driver',
  SingleDriver = 'Single Driver',
  ManagerDriver = 'Manager / Driver',
  FleetManager = 'customer',
}

export interface UserData {
  role: UserRole;
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  language: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authSubject$ = new BehaviorSubject(false);

  public role: UserRole;

  public isUserLoggedIn = false;

  public beforeAuthPage: boolean = false;
  public userData: UserData;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
  ) {
    this.isStillValid = this.isStillValid.bind(this);

    this.init();
  }

  async init() {
    const token = await this.storageService.get('ACCESS_TOKEN');
    if (token) {
      this.authSubject$.next(this.isStillValid(token));
    }

    setInterval(async () => {
      await this.storageService
        ?.get('ACCESS_TOKEN')
        ?.then(this.isStillValid)
        .then(async (valid) => {
          if (this.beforeAuthPage) {
            return;
          }

          if (!valid) {
            this.authSubject$.next(valid);

            await this.storageService.set('ACCESS_TOKEN', undefined);
          }
        });
    }, 10000);
  }

  private isStillValid(token: string) {
    if (!token) {
      return false;
    }

    const decodedToken = jwtDecode(token) as { TTL?: number };
    const validUntil = decodedToken.TTL || Number.MAX_SAFE_INTEGER;
    return Date.now() < validUntil;
  }

  login(username: string, password: string) {
    const credentials = {
      username: username,
      password: password,
    };
    const url: string = `${environment.carmovaApiUrl}/login`;

    return this.httpClient.post<LoginResponse>(url, credentials).pipe(
      catchError((error: HttpErrorResponse) =>
        this.handleError(error, 'movacarpro_login_failed'),
      ),
      take(1),
      switchMap((resData) => {
        const waitStorage = async () => {
          await this.storageService.set('ACCESS_TOKEN', resData.refreshToken);
          await this.storageService.set('APEX_TOKEN', resData.apexToken);

          return resData;
        };

        return from(waitStorage());
      }),
      switchMap(async (resData) => resData.refreshToken),
      tap((token: string) => this.authSubject$.next(this.isStillValid(token))),
    );
  }

  resetPassword(username: string) {
    const credentials = {
      username: username,
    };
    const url = `${environment.carmovaApiUrl}/login/reset-password`;

    return this.httpClient
      .post(url, credentials)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleError(error, 'movacarpro_error_reset_password_failed'),
        ),
      );
  }

  logout() {
    return from(
      (async () => {
        await this.storageService.set('ACCESS_TOKEN', undefined);
        await this.storageService.set('APEX_TOKEN', undefined);

        this.userData = {} as UserData;

        this.authSubject$.next(false);

        Sentry.setUser(null);
        this.isUserLoggedIn = false;
      })(),
    ).pipe(
      catchError((error) =>
        this.handleError(error, 'movacarpro_error_logout_failed'),
      ),
    );
  }

  isLoggedIn() {
    return this.authSubject$.asObservable();
  }

  getToken(): Observable<string | undefined> {
    return from((async () => await this.storageService.get('ACCESS_TOKEN'))());
  }

  getUserData(): Observable<UserData | undefined> {
    return this.getToken().pipe(
      map((token) => {
        if (!token) {
          return undefined;
        }

        const userData: UserData = jwtDecode(token) as UserData;

        Sentry.setUser({
          id: userData.id,
          email: userData.email,
        });

        this.userData = userData;

        return userData;
      }),
      catchError((error) =>
        this.handleError(error, 'movacarpro_error_load_user_data_failed'),
      ),
    );
  }

  getUserRole(): Observable<UserRole> {
    return this.getUserData().pipe(
      catchError((error) =>
        this.handleError(error, 'movacarpro_error_get_user_role_failed'),
      ),
      map((userData: UserData | undefined) => userData?.role as UserRole),
    );
  }

  private handleError(
    error: HttpErrorResponse,
    suggestedErrorMessage: string | undefined = undefined,
  ): Observable<never> {
    const errorMessagesToSupress: string[] = [
      'movacarpro_login_failed',
      'movacarpro_error_reset_password_failed',
      'movacarpro_error_logout_failed',
      'movacarpro_error_load_user_data_failed',
      'movacarpro_error_get_user_role_failed',
    ];

    const errorMessage: string =
      suggestedErrorMessage || 'movacarpro_error_message_unknown';

    // A client-side or network error occurred. Handle it as a Sentry Breadcrumb.
    if (error.status === 0) {
      return this.handleClientSideNetworkError(error);
    }

    if (errorMessagesToSupress.includes(errorMessage)) {
      return throwError(() => new Error(errorMessage));
    }

    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    Sentry.captureException(error);
    return throwError(() => new Error(errorMessage));
  }

  private handleClientSideNetworkError(
    error: HttpErrorResponse,
  ): Observable<never> {
    const breadCrumb: Breadcrumb = {
      message: error.error.message,
      level: 'info',
      data: {
        errorJson: JSON.stringify(error),
      },
    } as Breadcrumb;

    const hint: BreadcrumbHint = {
      knownCauses: ['A client-side or network error occurred'],
    } as BreadcrumbHint;

    Sentry.addBreadcrumb(breadCrumb, hint);
    return throwError(
      () => new Error('movacarpro_error_client_side_network_error'),
    );
  }
}
